const API_URL = 'https://api.policop.org/api'

export default {
    sendNotification({rootState}, data){
        return new Promise((resolve, reject) => {
            fetch(API_URL+'/sendNotification', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': rootState.userStore.accessToken
                },
                body: JSON.stringify(data),
                method: 'POST'
            })
            .then(res => res.json())
            .then(data => {
                resolve(data)
            })
            .catch(err => reject(err))
        })
    },
  }
