import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

const options = {
transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
};

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(Toast, options);
appInstance.use(VCalendar, {})

appInstance.mount("#app");
