import { ref } from 'vue'
import { Loader } from "@googlemaps/js-api-loader"

export const useGoogleMaps = () =>{
    const loader = ref(new Loader({
        apiKey: "AIzaSyARUnFpAqHZ3QrhzJxLJMQGFPqGdlA1fhk",
      }))

    return { loader }
}
