import alertTypes from '@/constants/alertTypes'
import {normalizeText} from '@/utils'
export default {
    getAlertsAnalytics: (state) =>{
        return state?.alertsByDate ? alertTypes.map(type => {
            const alerts = state.alertsByDate.filter( alert => normalizeText(alert.codigo) == normalizeText(type.label))
            return {
                title: type.label,
                value:alerts.length,
                icon:type.icon,
                color:type.color,
              }
        }) : []
    },
    getNationalAlerts: (state) =>{
        return state?.nationalAlerts.length ? alertTypes.map(type => {
            const alerts = state.nationalAlerts.filter( alert => {
                if(alert?.codigo){
                    return normalizeText(alert?.codigo) == normalizeText(type.label)
                }
                return false;
            })
            return {
                title: type.label,
                value:alerts.length,
                icon:type.icon,
                color:type.color,
              }
        }) : []
    },
    getAlertsAnalyticsByState:(state)=>{
        const estados = ['PENDIENTE', 'RECIBIDO', 'ATENDIDO', 'CANCELADO'];
        const objetoEstado = {};

        // Inicializar el objeto con los estados como claves
        estados.forEach(estado => {
            objetoEstado[estado] = [];
            
        });

        // Iterar sobre los datos y agregar cada registro al arreglo correspondiente según su estado
        alertTypes.forEach(type => {
            estados.forEach(estado => {
                const alerts = state.alertsByDate.filter( alert => (normalizeText(alert.codigo) == normalizeText(type.label)) && alert.estado == estado)
                objetoEstado[estado].push({
                    title: type.label,
                    value:alerts.length,
                    icon:type.icon,
                    color:type.color,
                });
            })
        });

        return objetoEstado
    }
}