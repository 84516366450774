// src/composables/useInactivityLogout.js
import { ref, onMounted, onUnmounted } from 'vue';
import { useFirebase } from './firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useRouter } from 'vue-router';

export function useInactivityLogout(logoutTime = 600000) { // 10 minutos por defecto
  const isWarningVisible = ref(false); // Controla la visibilidad del aviso
  let timeout;
  let warningTimeout; // Para mostrar el aviso de sesión por expirar
  const firebase = useFirebase(); // Acceso al servicio Firebase
  const auth = getAuth();
  const router = useRouter();

  // Cierra la sesión después de la inactividad
  const signOut = () => {
    firebase.logout()
    router.push('/');
  };

  // Reinicia el temporizador de inactividad
  const resetTimer = () => {
    clearTimeout(timeout);
    clearTimeout(warningTimeout);
    isWarningVisible.value = false;

    // Mostrar el aviso de cierre de sesión 1 minuto antes de cerrar la sesión
    warningTimeout = setTimeout(() => {
      isWarningVisible.value = true;
    }, logoutTime - 60000); // 1 minuto antes del cierre de sesión

    // Temporizador para cerrar la sesión
    timeout = setTimeout(signOut, logoutTime);
  };

  // Configura los eventos de interacción
  const setActivityListeners = () => {
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;
    document.onclick = resetTimer;
    document.ontouchstart = resetTimer;
  };

  // Verifica si hay una sesión activa
  const checkActiveSession = () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // Si hay un usuario autenticado, comienza a monitorear la inactividad
        console.log('Sesión activa. Iniciando monitoreo de inactividad.');
        setActivityListeners();
        resetTimer();
      } else {
        // Si no hay usuario, no hay necesidad de monitorear la inactividad
        console.log('No hay sesión activa.');
      }
    });
  };

  onMounted(() => {
    checkActiveSession(); // Revisa el estado de la autenticación al montar el componente
  });

  onUnmounted(() => {
    clearTimeout(timeout);
    clearTimeout(warningTimeout); // Limpia los temporizadores al desmontar
    // Remover los listeners de eventos
    window.onload = null;
    document.onmousemove = null;
    document.onkeydown = null;
    document.onclick = null;
    document.ontouchstart = null;
  });

  return {
    isWarningVisible,
  };
}
