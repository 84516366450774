export default {
  apiKey: "AIzaSyARUnFpAqHZ3QrhzJxLJMQGFPqGdlA1fhk",
  authDomain: "consultagpt-8bf80.firebaseapp.com",
  databaseURL: "https://consultagpt-8bf80-default-rtdb.firebaseio.com",
  projectId: "consultagpt-8bf80",
  storageBucket: "consultagpt-8bf80.appspot.com",
  messagingSenderId: "392179411697",
  appId: "1:392179411697:web:0e85938f96c24d474d5750",
  measurementId: "G-B5FN1JXXHR"
};
