<script setup>
import { ref, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import {format} from 'date-fns'
import { useToast } from "vue-toastification";
import {useFirebase} from '../../composables/firebase'


const toast = useToast();
const {getCurrentUser } = useFirebase()
const store = useStore()
const users = ref(null)
const isCreate = ref(true)
const isModalOpen = ref(false)
const loading = ref(false)
const toogleStateOpen = ref(false)
const toggleStateData = ref(null)
const userData = ref({
    "email":"",
    "password":"",
    "name":"",
    "ciudad":"",
    "direccion":"",
    "role":"",
    "phone":"",
    "fechaRegistro":"",
    "fechaVencimiento":"",
    "area":""
})

onBeforeMount(() => {
  init()
})

const init = async()=>{
  if(await getCurrentUser()){
      await getUsers()
    }
}

const createUser = () => {
  loading.value = true
  userData.value.fechaRegistro = format(new Date(), 'dd/MM/yyyy')
  store.dispatch('createUser', userData.value)
  .then(async () => {
    await getUsers()
    toast.success('El usuario se ha registrado con éxito')
  })
  .catch(err => {
    console.log(err)
    toast.error('Ups. Ha ocurrido un error al registrar el usuario')
  })
  .finally(()=> {
    closeModal()
  })
}

const getUsers = () => {
  return new Promise((resolve, reject) => { 
      store.dispatch('getUsers')
      .then(resp => {
        users.value = resp
        resolve(resp)
      })
      .catch(err => {
        toast.error('Ups. Ha ocurrido un error al cargar los datos')
        reject(err)
      })
  })
}

const editUser = (item) =>{
  isModalOpen.value = true
  isCreate.value = false
  userData.value = {
    "email":item.email,
    "name":item.name,
    "ciudad":item.ciudad,
    "direccion":item.direccion,
    "role":item.role,
    "phone":item.celular,
    "fechaRegistro":item.fechaRegistro,
    "fechaVencimiento":item.fechaVencimiento,
    "uid":item.uid,
    "area":item.area,
  }
}

const updateUser =()=>{
  loading.value = true
  store.dispatch('updateUser', userData.value)
  .then(async () => {
    await getUsers()
    toast.primary('El usuario se ha actualizado con éxito')
  })
  .catch(err => {
    console.log(err)
    toast.error('Ups. Ha ocurrido un error al actualizar el usuario')
  })
  .finally(()=> {
    closeModal()
  })
}

const closeModal = ()=>{
  loading.value = false
  isModalOpen.value = false
  isCreate.value = true
  userData.value = {
    "email":"",
    "password":"",
    "name":"",
    "ciudad":"",
    "direccion":"",
    "role":"",
    "phone":"",
    "fechaRegistro":"",
    "fechaVencimiento":""
  }
}

const toogleState = (uid, state)=>{
  toogleStateOpen.value = true
  toggleStateData.value = {
    uid,state
  }
}

const cancelChangeState = ()=>{
  toogleStateOpen.value = false
  toggleStateData.value = null
  loading.value = false
}

const updateState = () => {
  loading.value = true
  store.dispatch('toggleState', toggleStateData.value)
  .then(async () => {
    await getUsers()
    toast.primary('El usuario se ha actualizado con éxito')
  })
  .catch(err => {
    console.log(err)
    toast.error('Ups. Ha ocurrido un error al actualizar el usuario')
  })
  .finally(()=> {
    cancelChangeState()
  })
}
</script>

<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex justify-content-between">
        <h6>Todas los usuarios</h6>
        <!-- <span class="badge bg-light text-dark mb-2 cursor-pointer ms-2" data-bs-toggle="modal" data-bs-target="#tableFilterModal">
          <i class="ni ni-ui-04"></i>
          Filtros
        </span> -->
        <button class="btn btn-primary" @click="isModalOpen = true">
          <i class="fas fa-plus"></i>
          Nuevo usuario
        </button>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0 px-4" style="overflow: auto !important;">
        <table class="table align-items-center mb-0" v-if="users?.length">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">

              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Nombre
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                Email
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Ciudad
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Dirección
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Celular
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Rol
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Área
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Fecha de registro
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Fecha de vencimiento
              </th>

              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Estado
              </th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              </th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="item in users" :key="item.uid">
              <td class="align-middle">
                <i class="far fa-edit cursor-pointer" @click="editUser(item)"></i>
              </td>
              <td class="align-middle">
                <span class="text-secondary text-xs font-weight-bold">{{ item.name }}</span>
              </td>
              <td class="align-middle">
                <span class="text-secondary text-xs font-weight-bold">{{ item.email }}</span>
              </td>
              <td class="align-middle">
                <span class="text-secondary text-xs font-weight-bold">{{ item.ciudad }}</span>
              </td>
              <td class="align-middle">
                <span class="text-secondary text-xs font-weight-bold">{{ item.direccion }}</span>
              </td>
              <td class="align-middle">
                <span class="text-secondary text-xs font-weight-bold">{{ item.celular }}</span>
              </td>
              <td class="align-middle">
                <span class="text-secondary text-xs font-weight-bold">{{ item.role }}</span>
              </td>
              <td class="align-middle">
                <span class="text-secondary text-xs font-weight-bold">{{ item?.area ?store.state.areasStore.areas.find(area => item?.area == area.id)?.nombre : 'No asignado' }}</span>
              </td>
              <td class="align-middle">
                <span class="text-secondary text-xs font-weight-bold">{{ item.fechaRegistro }}</span>
              </td>
              <td class="align-middle">
                <span class="text-secondary text-xs font-weight-bold">{{ item.fechaVencimiento }}</span>
              </td>
              <td class="align-middle">
                <span class="text-secondary text-xs font-weight-bold">
                  {{ item.disabled ? 'Deshabilitado' : 'Habilitado'}}
                </span>
              </td>
              <td class="align-middle text-center">
                <span class="badge badge-sm border-0 cursor-pointer" :class="{'bg-danger':!item.disabled,'bg-primary':item.disabled}" @click="toogleState(item.uid, item.disabled)">
                  {{ item.disabled ? 'Habilitar' : 'Deshabilitar'}}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="loader_container" v-else>
          <span class="loader"></span>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade show" id="exampleModal" tabindex="-1" 
    style="display: block;background: rgba(0, 0, 0, 0.5);" aria-modal="true" role="dialog" v-if="isModalOpen">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ 
              isCreate ? 'Nuevo usuario' : 'Actualizar usuario'
            }}
          </h5>
        </div>
        <form action="" @submit.prevent="()=> isCreate ? createUser() : updateUser()">
        <div class="modal-body">
            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="email" class="form-label">Email</label>
                  <input type="email" class="form-control" id="email" placeholder="Ingrese el Email" required v-model="userData.email">
                </div>
              </div>
              <div class="col-6" v-if="isCreate">
                <div class="mb-3">
                  <label for="password" class="form-label">Contraseña</label>
                  <input type="password" class="form-control" id="password" placeholder="Ingrese el Contraseña" required v-model="userData.password"> 
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="names" class="form-label">Nombres</label>
                  <input type="text" class="form-control" id="names" placeholder="Ingrese el nombre" required v-model="userData.name">
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="phone" class="form-label">Nro. de teléfono</label>
                  <input type="number" class="form-control" id="phone" placeholder="Ingrese el Nro. de teléfono" v-model="userData.phone">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="city" class="form-label">ciudad</label>
                  <input type="text" class="form-control" id="city" placeholder="Ingrese la ciudad" required v-model="userData.ciudad">
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="location" class="form-label">Dirección</label>
                  <input type="text" class="form-control" id="location" placeholder="Ingrese la dirección" v-model="userData.direccion">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="date" class="form-label">Fecha de vencimiento</label>
                  <input type="text" class="form-control" id="date" placeholder="Ingrese la Fecha de vencimiento" required v-model="userData.fechaVencimiento">
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label class="form-label">Rol</label>
                  <select class="form-select" aria-label="Default select example" required v-model="userData.role">
                    <option selected value="">Seleciona el rol</option>
                    <option value="ADMIN">Admin</option>
                    <option value="COLABORADOR">Colaborador</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label class="form-label">Área</label>
                  <select class="form-select" aria-label="Default select example" v-model="userData.area">
                    <option selected value="">Seleciona el </option>
                    <option :value="item.id" v-for="(item) in store.state.areasStore.areas" :key="item.id">{{ item.nombre }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">Cerrar</button>
            <button type="submit" class="btn btn-primary">
              {{
                loading ? 'Guardando datos...' : 'Guardar'
              }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="modal fade show" id="exampleModal" tabindex="-1" 
    style="display: block;background: rgba(0, 0, 0, 0.5);" aria-modal="true" role="dialog" v-if="toogleStateOpen">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ 
              toggleStateData.state ? 'Habilitar usuario' : 'Deshabilitar usuario'
            }}
          </h5>
        </div>
        <div class="modal-body">
          <h6 class="modal-title" id="exampleModalLabel">
            {{ 
              toggleStateData.state ? '¿Estás seguro de que quieres habilitar este usuario?' : '¿Estás seguro de que quieres deshabilitar este usuario?'
            }}
          </h6>
        </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="cancelChangeState">Cancelar</button>
            <button type="submit" class="btn" @click="updateState" :class="{ 'btn-danger': !toggleStateData.state, 'btn-primary': toggleStateData.state}">
              {{
                loading ? 'Actualizando estado...' : toggleStateData.state ? 'Habilitar' : 'Deshabilitar'
              }}
            </button>
          </div>
      </div>
    </div>
  </div>

</template>
