export default {
    setAlerts(state, payload){
        state.alerts = payload
    },
    setAlertsByDate(state, payload){
        state.alertsByDate = payload
    },
    setAllAllerts(state, payload){
        state.allAlerts = payload
    },
    setNationalAlerts(state, payload){
        state.nationalAlerts = payload
    },

    // firebase listeners
    setPreviousOpenAlertsRef(state, payload){
        state.previousOpenAlertsRef = payload
    },
    setPreviousOpenListener(state, payload){
        state.previousOpenListener = payload
    },

    setPreviousByDateAlertsRef(state, payload){
        state.previousByDateAlertsRef = payload
    },
    setPreviousByDateListener(state, payload){
        state.previousByDateListener = payload
    },

    setPreviousAllAlertsRef(state, payload){
        state.previousAllAlertsRef = payload
    },
    setPreviousAllListener(state, payload){
        state.previousAllListener = payload
    }
}