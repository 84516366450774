import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import firebaseConfig from "../constants/firebaseConfig";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { getDatabase, ref as fref, onValue, query, orderByChild, startAt, endAt, update, equalTo, push, set, remove,off, get} from "firebase/database";
import { useStore } from "vuex";
import { format } from "date-fns";
import {computed, toRaw} from 'vue'


export function useFirebase() {
  const app = initializeApp(firebaseConfig);
  const db = getDatabase(app);
  const store = useStore();
  const auth = getAuth();

  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LevtaUpAAAAAHff9xMXr4B4LQ7fdUnnGfg0AO3w'),
  
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });

  const signIn = (email, password) => {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          getUser();
          getCurrentUser().then(() => {
            setTimeout(()=>{
              saveEventLog('Inició sesión', 'sesion')
            },500)
          })
          resolve(auth.currentUser);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  

  const getUser = async () => {
    const idToken = await auth.currentUser.getIdTokenResult()
    store.commit("setAuth", {
      accessToken: auth.currentUser.accessToken,
      uid: auth.currentUser.uid,
      idToken
    });
    const users = fref(db, "Alertas_Usuarios/" + store.state.userStore.uid);
    onValue(users, (snapshot) => {
      const data = snapshot.val();
      store.commit("setUser", data);
      // if(!store.state.userStore.alertsLocation){
      //   store.commit('setAlertsLocation', data.ciudad)
      // }
      getLocations()
      getOpenAlerts()
      getAlertsByDate()
      getSessions()
      getAllAlerts()
      getAreas()

    });
  };

  const getOpenAlerts = (startDate = new Date(), endDate = new Date()) => {
    const start = format(new Date(startDate), 'dd/MM/yyyy 00:00')
    const end = format(new Date(endDate), 'dd/MM/yyyy 23:59')

    const pendingAlerts = query(fref(db, "Alertas_SOS_areas/" + store.state.userStore.alertsLocation), orderByChild('fecha'), startAt(start), endAt(end))

    if(store.state.alertsStore.previousOpenAlertsRef && store.state.alertsStore.previousOpenListener){
      off(toRaw(store.state.alertsStore.previousOpenAlertsRef),'value', toRaw(store.state.alertsStore.previousOpenListener))
    }

    const listener = (snapshot) => {
      const data = snapshot.val();
      console.log(data)
      if (data) {
        const filtered = Object.entries(data)
          .map(item => ({ ...item[1], id: item[0] }))
          .filter(item => item.estado === "PENDIENTE" || item.estado === "RECIBIDO");
        store.commit("setAlerts", filtered);
      } else {
        store.commit("setAlerts", []);
      }
    };

    onValue(pendingAlerts, listener);

    store.commit("setPreviousOpenAlertsRef", pendingAlerts);
    store.commit("setPreviousOpenListener", listener);
  };

  const getAlertsByDate = (startDate = new Date(), endDate = new Date()) => {
    const start = format(new Date(startDate), 'dd/MM/yyyy 00:00')
    const end = format(new Date(endDate), 'dd/MM/yyyy 23:59')

    const alerts = query(fref(db, "Alertas_SOS_areas/" + store.state.userStore.alertsLocation), orderByChild('fecha'), startAt(start), endAt(end))
    if(store.state.alertsStore.previousByDateAlertsRef && store.state.alertsStore.previousByDateListener){
      off(toRaw(store.state.alertsStore.previousByDateAlertsRef), 'value', toRaw(store.state.alertsStore.previousByDateListener))
    }
    const listener = (snapshot) => {
      const data = snapshot.val();
      if(data){
        const filtered = Object.entries(data)
        .map(item => ({...item[1], id: item[0]}))
        store.commit("setAlertsByDate", filtered);
      }else{
        store.commit("setAlertsByDate", []);
      }
    }

    onValue(alerts, listener);
    store.commit("setPreviousByDateAlertsRef", alerts);
    store.commit("setPreviousByDateListener", listener);
  };

  const getAllAlerts = () => {
    const alerts = fref(db, "Alertas_SOS_areas/" + store.state.userStore.alertsLocation)
    if(store.state.alertsStore.previousAllAlertsRef && store.state.alertsStore.previousAllListener){
      off(toRaw(store.state.alertsStore.previousAllAlertsRef), 'value', toRaw(store.state.alertsStore.previousAllListener))
    }

    const listener = (snapshot) => {
      const data = snapshot.val();
      if(data){
        const filtered = Object.entries(data)
        .map(item => ({...item[1], id: item[0]}))
        store.commit("setAllAllerts", filtered);
      }else{
        store.commit("setAllAllerts", []);
      }
    }

    onValue(alerts, listener);

    store.commit("setPreviousAllAlertsRef", alerts);
    store.commit("setPreviousAllListener", listener);
  };

  const getSessions = (startDate = new Date(), endDate = new Date()) => {
    const start = format(new Date(startDate), 'dd/MM/yyyy 00:00')
    const end = format(new Date(endDate), 'dd/MM/yyyy 23:59')

    const alerts = query(fref(db, "user_events"), orderByChild('fecha'), startAt(start), endAt(end))

    const listener = (snapshot) => {
      const data = snapshot.val();
      if(data){
        const filtered = Object.entries(data)
        .map(item => ({...item[1], id: item[0]}))
        store.commit("setSessions", filtered);
      }else{
        store.commit("setSessions", []);
      }
    }

    onValue(alerts, listener);
  };

  const getCurrentUser = () =>{
    return new Promise((resolve, reject) => {
      const removeListener = onAuthStateChanged(
        auth,
        (user) => {
          removeListener()
          resolve(user)
        },
        reject
      )
    })
  }

  const logout = () => {
    signOut(auth).then(() => {
      saveEventLog('Cerró sesión', 'sesion')
      setTimeout(() => {
        store.commit("setUser", {
          nombre:'',
          ciudad:'',
          celular:''
        });
        store.commit("setAuth", {
          accessToken: '',
          uid: '',
        });
      }, 100);
      return true
    }).catch((error) => {
      console.log(error)
    });
  }

  const updateAlertState = async (state, uuid) => {
    const registroRef = fref(db, `Alertas_SOS_areas/${store.state.userStore.alertsLocation}/${uuid}`);
  
    try {
      // Actualizar el estado de la alerta
      await update(registroRef, { estado: state });
      await saveEventLog('Actualizó estado', 'alerta', uuid);
  
      // Obtener los datos de la alerta
      const alertaSnapshot = await get(registroRef);
      const alertaData = alertaSnapshot.val();
  
      console.log(alertaData)
      if (alertaData) {
        const { nombre, telefono } = alertaData;
        // Buscar el token del usuario en Alertas_Usuarios
        const usuarioRef = fref(db, 'Alertas_Usuarios');
        const usuarioSnapshot = await get(usuarioRef);
        const usuarios = usuarioSnapshot.val();
  
        let tokenNoti;
        for (const key in usuarios) {

          // Verificar si el nombre y el teléfono coinciden con el registro de la alerta
          if (usuarios[key]?.nombre?.split(' ')?.join('') == nombre?.split(' ')?.join('') && usuarios[key].celular === telefono) {
            tokenNoti = usuarios[key].tokenNoti;
            break;
          }
        }

  
        if (tokenNoti) {
          // Enviar notificación push
          const message = {
            title: 'Actualización de Alerta SOS',
            body: `El estado de tu alerta ha sido actualizado a: ${state}`,
            tokenNoti: tokenNoti
          };
  
          try {
            const resp = await store.dispatch('sendNotification', message)
            console.log(resp)
          } catch (error) {
            console.error('Error al enviar la notificación:', error);
          }
        } else {
          console.log('No se encontró el token de notificación para el usuario');
        }
      }
    } catch (error) {
      console.error("Error al actualizar registro o enviar notificación:", error);
    }
  };

  const nationalAlerts = () => {
    const pendingAlerts = fref(db, "Alertas_SOS_areas")

    onValue(pendingAlerts, (snapshot) => {
      const data = snapshot.val();
      if(data){
        const filtered = Object.values(data).map(item => Object.values(item)).flat()
                        .filter(item => item.estado !== "CANCELADO");
        store.commit("setNationalAlerts", filtered);
      }else{
        store.commit("setNationalAlerts", []);
      }
    });
  };

  const getLocations = () => {
    const pendingAlerts = fref(db, "Alertas_SOS_areas")

    onValue(pendingAlerts, (snapshot) => {
      const data = snapshot.val();
      if(data){
        const filtered = Object.keys(data)
        store.commit("setLocations", filtered);
      } else{
        store.commit("setLocations", []);
      }
    });
  };


  const getAreas = () => {
    const areasRef = fref(db, "map_areas");
    const areasQuery = query(areasRef, orderByChild("ubicacion"), equalTo(store.state.userStore.city));
  
    onValue(areasQuery, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const filtered = Object.entries(data).map(item => ({id:item[0], ...item[1]}));
        store.commit("setAreas", filtered);
      }
    });
  };

  const areas = computed(() => store.state.areasStore.areas ?? [])

  const createArea = (name, area, phone) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const reference = fref(db, `map_areas/`);
        const newAlertRef = push(reference);
        await set(newAlertRef, {
          nombre:name,
          area:area,
          ubicacion: store.state.userStore.city,
          celular: phone
        });
        resolve(newAlertRef.key);
      } catch (error) {
        reject(`Ocurrió un error al registrar: ${error}`);
      }
    });
  }

  const updateArea = (id, name, area, phone) => {
    return new Promise((resolve, reject) => {
      const registroRef = fref(db, `map_areas/${id}`);
      update(registroRef, { nombre:name, area:area, celular: phone })
      .then(() => {
        resolve("Área actualizada correctamente");
      })
      .catch((error) => {
        reject("Error al actualizar registro:", error);
      });
    })
  }

  const deleteArea = (id) => {
    return new Promise((resolve, reject) => {
      const alertasRef = fref(db, `Alertas_SOS_areas/${id}`);
      remove(alertasRef)
      const registroRef = fref(db, `map_areas/${id}`);
      remove(registroRef)
      .then(() => {
        resolve("Área eliminada correctamente");
      })
      .catch((error) => {
        reject("Error al eliminar registro:", error);
      });
    })
  }
  
  const saveEventLog = (evento, type, id = '') =>{
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const reference = fref(db, `user_events/`);
        const newAlertRef = push(reference);
        await set(newAlertRef,{
          id:store.state.userStore.uid,
          nombre: store.state.userStore.name,
          fecha: format(new Date(), 'dd/MM/yyyy hh:mm'),
          evento: evento,
          tipo: type,
          rol: store.state.userStore.idToken?.claims?.role,
          alerta: id
        });
        resolve(newAlertRef.key);
      } catch (error) {
        reject(`Ocurrió un error al registrar: ${error}`);
      }
    });
  }

  const validateAreaPhone = (phone) => {
    return new Promise((resolve) => {
      const areasRef = fref(db, "map_areas");
      const areasQuery = query(areasRef, orderByChild("celular"), equalTo(phone));
    
      onValue(areasQuery, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const filtered = Object.entries(data).map(item => ({id:item[0], ...item[1]}));
          resolve(filtered);
        } else {
          resolve([]);
        }
      });
    });
  }

  return { 
    signIn, 
    getUser, 
    getOpenAlerts, 
    getCurrentUser, 
    getAlertsByDate, 
    logout, 
    getAllAlerts, 
    updateAlertState, 
    nationalAlerts, 
    getLocations,
    getAreas,
    areas,
    createArea,
    updateArea,
    deleteArea,
    getSessions,
    validateAreaPhone
  };
}
