export default {
    alerts:[],
    alertsByDate: [],
    allAlerts: [],
    nationalAlerts: [],

    previousOpenAlertsRef: null,
    previousOpenListener: null,

    previousByDateAlertsRef: null,
    previousByDateListener: null,
    
    previousAllAlertsRef: null,
    previousAllListener: null,
}