export default {
    setUser(state, payload){
        state.name = payload.nombre
        state.city = payload.ciudad
        state.phone = payload.celular
        if(state.idToken?.claims?.role !== "ADMIN"){
            state.alertsLocation = payload.area
        }
    },
    setAuth(state, payload){
        state.accessToken = payload.accessToken
        state.uid = payload.uid
        state.idToken = payload.idToken
    },
    setAlertsLocation(state, payload){
        localStorage.setItem('alertsLocation', payload)
        state.alertsLocation = payload
    },
    setLocations(state, payload){
        state.locations = payload
    }
}