import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Profile from "../views/Profile.vue";
import Signin from "../views/Signin.vue";
import MapVue from "../views/Map.vue";
import Areas from "../views/Areas.vue";
import Alerts from "../views/Alerts.vue";
import Users from "../views/Users.vue";
import Sessions from "../views/Sessions.vue";
import {useFirebase} from '../composables/firebase'
import { useStore } from "vuex";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/signin",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/map",
    name: "Mapa",
    component: MapVue,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: "/areas",
    name: "Areas",
    component: Areas,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: "/alerts",
    name: "Alertas",
    component: Alerts,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: "/users",
    name: "Usuarios",
    component: Users,
    meta:{
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: "/sessions",
    name: "Reporte de sesiones",
    component: Sessions,
    meta:{
      requiresAuth: true,
      admin: true
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  const {getCurrentUser, getUser} = useFirebase()
  const store = useStore()

  if(to.matched.some((record)=>record.meta.requiresAuth)){
    if(await getCurrentUser()){
      if(!store.state.userStore.name){
        await getUser()
      }
      if(to.matched.some((record)=>record.meta.admin)){
        if(store.state.userStore.idToken?.claims?.role == "ADMIN"){
          next()
        }else{
          next('/dashboard')
        }
      }
      next()
    }else{
      next('/')
    }
  }else{
    next()
  }
})

export default router;
