export default {
    name:'',
    accessToken:'',
    city:'',
    phone:'',
    uid:'',
    area: '',
    alertsLocation: localStorage.getItem('alertsLocation') || null,
    locations: null,
    idToken: null
}