<script setup>
import { computed, inject, ref } from 'vue';
import {useStore} from 'vuex'
import StatusBadget from '@/components/StatusBadget'
import alertTypes from '@/constants/alertTypes'
import {normalizeText} from '@/utils'
import * as XLSX from 'xlsx';
import { useRouter } from "vue-router";
import { parse, compareAsc } from 'date-fns'
const {setAlertPosition}= inject('alertCoords')
const store = useStore()
const router = useRouter()

const estados = ref([
  {
    label:'PENDIENTE',
    value: true
  }, 
 {
    label:'RECIBIDO',
    value:true
  }, 
  {
    label:'ATENDIDO',
    value:true
  }, 
  {
    label:'CANCELADO',
    value:true
  }
])

const alertFilters = ref([...alertTypes.map(item => ({label:item.label, value: true}))])

const alerts = computed(() => {
  return store.state.alertsStore.alertsByDate.filter(item => {
    if(alertFilters.value){
      const filter = alertFilters.value.find(filterItem => {
        return normalizeText(filterItem.label) == normalizeText(item.codigo)
      })
      return filter
    }
    return item
  })
  .filter(item => {
    const filter = estados.value.find(filterItem => {
      return normalizeText(filterItem.label) == normalizeText(item.estado)
    })
    return filter.value
  })
  .sort((a,b) => {
    const dateA = parse(a.fecha, 'dd/MM/yyyy HH:mm', new Date())
    const dateB = parse(b.fecha, 'dd/MM/yyyy HH:mm', new Date())
    return compareAsc(dateB, dateA)
  })
})

const {setAlertModal}= inject('alertModal')

const exportExcel = () => {
  const worksheet = XLSX.utils.json_to_sheet(alerts.value);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, `reporte-alertas-${new Date().getTime()}.xlsx`);
}

const setAlertPositionMethod = (item)=>{
  setAlertPosition(item)
  router.push('/map')
}

</script>

<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="d-flex">
        <h6>Reporte de alertas</h6>
        <span class="badge bg-primary text-white mb-2 cursor-pointer ms-2" @click="exportExcel">
          <i class="fas fa-file-download"></i>
          Excel
        </span>
        <span class="badge bg-light text-dark mb-2 cursor-pointer ms-2" data-bs-toggle="modal" data-bs-target="#tableFilterModal">
          <i class="ni ni-ui-04"></i>
          Filtros
        </span>
      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0 px-4">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Código
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Dato
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Estado
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Fecha
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Lugar
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Nombre
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Teléfono
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
              </th>
            </tr>
          </thead>
          <tbody v-if="alerts?.length">
            <tr v-for="item in alerts" :key="item.id">
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ item.codigo }}</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ item.dato }}</span>
              </td>
              <td class="align-middle text-center text-sm">
                <StatusBadget :estado="item.estado" :id="item.id" />
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold"
                  >{{ item.fecha }}</span
                >
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ item.lugar }}</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ item.nombre }}</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ item.telefono }}</span>
              </td>
              <td class="align-middle text-center">
                <i class="fa fa-crosshairs cursor-pointer" @click="setAlertPositionMethod(item)"></i>
              </td>
              <td class="align-middle text-center">
                <i class="fa fa-eye cursor-pointer" aria-hidden="true" @click="()=>setAlertModal(item.id)" data-bs-toggle="modal" data-bs-target="#alertModal"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="modal fade" id="tableFilterModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Filtro de alertas</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6">
            <h6>Código</h6>
            <div class="form-check form-switch" v-for="(item, index) in alertFilters" :key="index">
              <input class="form-check-input" type="checkbox" :id="'codigo'+index" checked v-model="item.value">
              <label class="form-check-label" :for="'codigo'+index">{{item.label}}</label>
            </div>
          </div>
          <div class="col-6">
            <h6>Estado</h6>
            <div class="form-check form-switch" v-for="(item, index) in estados" :key="index">
              <input class="form-check-input" type="checkbox" :id="'estado'+index" checked v-model="item.value">
              <label class="form-check-label" :for="'estado'+index">{{item.label}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Aplicar</button>
      </div>
    </div>
  </div>
</div>
</template>

