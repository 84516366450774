<script setup>
import { onMounted } from "vue";


import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
onMounted(() => {

  setNavPills();
  setTooltip();
});

</script>
<template>
  <div></div>
</template>
