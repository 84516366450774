<script setup>
import { ref } from 'vue';
import {useFirebase} from '@/composables/firebase'

const {updateAlertState} = useFirebase()

const props = defineProps(['estado', 'id', 'readOnly'])

const estados = ref({
  'PENDIENTE':{
    label:'Pendiente',
    class:'bg-gradient-warning'
  }, 
  'RECIBIDO':{
    label:'Recibido',
    class:'bg-gradient-primary'
  }, 
  'ATENDIDO':{
    label:'Atendido',
    class:'bg-gradient-success'
  }, 
  'CANCELADO':{
    label:'Cancelado',
    class:'bg-gradient-danger'
  }
})

const filterActions = {
  'PENDIENTE': () => Object.entries(estados.value).filter(item => item[0] == 'RECIBIDO' || item[0] == 'CANCELADO'),
  'RECIBIDO': () => Object.entries(estados.value).filter(item => item[0] == 'ATENDIDO' || item[0] == 'CANCELADO'),
  'ATENDIDO': () => Object.entries(estados.value).filter(item => item[0] == 'ATENDIDO'),
  'CANCELADO': () => Object.entries(estados.value).filter(item => item[0] == 'CANCELADO')
}

const updateState = (estado, id, e) => {
  updateAlertState(estado, id)
  e.target.parentElement.parentElement.classList.remove('show')
}

</script>

<template>
    <div class="btn-group">
        <button class="badge badge-sm border-0" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" :class="{[estados[props.estado].class]: true, 'dropdown-toggle': !props.readOnly, disabled:props.readOnly }" >
        {{ estados[props.estado].label }}
        </button>
        <ul class="dropdown-menu shadow" aria-labelledby="defaultDropdown">
        <li v-for="(state, index) in filterActions[props.estado]()" :key="index">
            <a class="dropdown-item" :class="{disabled: state[0] == props.estado}" href="#" @click="($event)=>updateState(state[0], props.id, $event)">{{ state[1].label }}</a>
        </li>
        </ul>
    </div>
</template>
